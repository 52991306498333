<template>
  <div class="mt-2">
    <div class="mb-4">
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search by email"
        class="input w-full"
      />
    </div>

    <div 
      v-if="filteredEmails.length > 0"
      class="rounded-lg overflow-hidden border text-sm">
      <table class="table-auto w-full">
        <thead class="bg-neutral-100 border-b">
          <tr>
            <th class="p-2 text-left pl-4 w-1/2">Email address</th>
            <th class="p-2 text-left w-1/4">Date added</th>
            <th class="p-2 text-right w-1/4">Action</th>
          </tr>
        </thead>
        <tbody class="max-h-80 overflow-y-auto block">
          <tr v-for="email in filteredEmails" :key="email.id" class="hover:bg-neutral-50">
            <td class="p-2 pl-4 truncate lowercase w-1/2">
            <span
              v-tippy="{ 
                content: email.email_address, 
                placement: 'bottom',
                maxWidth: 'none',
                allowHTML: false,
                interactive: true,
                appendTo: 'parent'
              }"
              >{{ email.email_address }}
            </span>
          </td>
            <td class="p-2 w-1/4">{{ email.created_at | humanDate }}</td>
            <td class="p-2 text-right w-1/4">
              <a href="#" @click.prevent="destroyEmail(email)">Remove</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <p class="text-neutral-700 text-sm mt-1 border border-dashed rounded-lg w-full p-2">
        No email addresses found
      </p>
    </div>

    <transition name="fromLeft" mode="out-in" appear>
      <p
        class="mb-1 text-neutral-700"
        v-if="allowedEmails.length === 0 && !isEmailAdding"
      >
        No allowed email addresses yet
      </p>

      <p class="mb-1 text-neutral-700" v-if="isEmailRemoving || isEmailAdding">
        Working …
      </p>
    </transition>

    <form action class="mt-4">
      <div class="flex items-center">
        <input
          type="text"
          class="input mr-2"
          placeholder="Example: jenny@company.com"
          v-model="newEmail"
          :disabled="isEmailAdding"
        />
        <button
          type="submit"
          class="btn-main flex-shrink-0"
          @click.prevent="addEmail()"
          :disabled="isEmailAdding"
        >
          Add allowed email address
        </button>
      </div>
      <p v-if="emailError.length > 1" class="text-red-500 text-sm mt-1">
        {{ emailError }}
      </p>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    emails: Array,
  },
  data: function () {
    return {
      allowedEmails: this.emails,
      newEmail: "",
      emailError: "",
      isEmailAdding: false,
      isEmailRemoving: false,
      searchQuery: "",
    };
  },

  computed: {
    filteredEmails() {
      if (!this.searchQuery) return this.allowedEmails;
      const query = this.searchQuery.toLowerCase();
      return this.allowedEmails.filter(email => 
        email.email_address.toLowerCase().includes(query)
      );
    }
  },

  methods: {
    isEmailValid: function () {
      // Not perfect, but good enough to prevent typos
      return /^[\w.-]+@[\w-]+\.[\.\w+-]*\w$/.test(this.newEmail);
    },

    addEmail: function () {
      this.emailError = "";

      if (!this.isEmailValid()) {
        this.emailError =
          'Not a valid email address. Please enter an email address like "support@feedbear.com"';
        return;
      }

      this.isEmailAdding = true;
      var params = new FormData();
      params.append("email_address", this.newEmail);

      Rails.ajax({
        url: "/invited_customers",
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.newEmail = "";
          this.allowedEmails.push(data);
          this.isEmailAdding = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isEmailAdding = false;
        },
      });
    },

    destroyEmail: function (email) {
      this.isEmailRemoving = true;

      Rails.ajax({
        url: "/invited_customers/" + email.id,
        type: "DELETE",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.allowedEmails.splice(this.allowedEmails.indexOf(email), 1);
          this.isEmailRemoving = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isEmailRemoving = false;
        },
      });
    },
  },

  filters: {
    humanDate: function (date) {
      let event = new Date(date);
      let options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      return event.toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style scoped>
tbody {
  display: block;
  max-height: 20rem;
  overflow-y: auto;
}

thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
</style>
