<template>
  <div>
    <PrivacyAlert v-if="!privacyCheck" title="Upgrade your plan to use this feature" :description="getAppropriateDescription" :link="'/edit?view=billing'" :click="'Click here to upgrade'" />
    <h3 class="text-xl font-bold text-neutral-800">Privacy</h3>
    <p class="text-neutral-800">
      Control who can access your project (see the roadmap, boards, and ideas).
      Recommended for closed beta testing or&nbsp;internal projects.
    </p>

    <div
      class="bg-orange-100 text-neutral-800 px-3 py-2 rounded mt-4 flex items-center"
      v-if="this.isIntercomConnected"
    >
      <svg
        class="w-6 h-6 flex-shrink-0 mr-4 ml-2 text-orange-900"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        ></path>
      </svg>
      <p>
        Your project is connected to an Intercom workspace. These privacy
        settings have no effect on our Intercom app. You can control who has
        access to the Intercom app in your Messenger settings.
      </p>
    </div>

    <h4 class="mt-4 font-semibold text-neutral-800">Your project</h4>

    <div class="toggle-wrapper mt-1">
      <div
        class="button--toggle"
        :class="{ 'area--disabled': isPrivateToggleLoading }"
      >
        <input
          :disabled="!privacyCheck"
          type="checkbox"
          class="checkbox"
          @change="togglePrivacy()"
          v-model="isProjectPrivate"
        />
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
      <transition name="fromLeft" mode="out-in" appear>
        <span v-if="!isPrivateToggleLoading" key="loading1">
          <span
            v-if="isProjectPrivate"
            key="isprivate"
            class="flex items-center"
          >
            <svg
              class="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span><strong>Private</strong> – only allowed users can view</span>
          </span>
          <span v-else key="ispublic" class="flex items-center">
            <svg
              class="w-5 h-5 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"
              ></path>
            </svg>
            <span><strong>Public</strong> – anybody can view</span>
          </span>
        </span>
        <span class="text-neutral-700" v-else key="loading2">Saving …</span>
      </transition>
    </div>

    <div v-if="showSSOPrivacySetting">
      <h4 class="mt-4 font-semibold text-neutral-800">SSO Privacy</h4>

      <div class="toggle-wrapper mt-1">
        <div
          class="button--toggle"
          :class="{ 'area--disabled': isPrivateToggleLoading }"
        >
          <input
            type="checkbox"
            class="checkbox"
            @change="toggleSSOPrivacy()"
            v-model="isSsoEnable"
          />
          <div class="knobs"></div>
          <div class="layer"></div>
        </div>
        <transition name="fromLeft" mode="out-in" appear>
          <span key="loading1">
            <span
              v-if="!isSsoEnable"
              key="isprivate"
              class="flex items-center"
            >
              <span>Only allowed users will be able to see private pages</span>
            </span>
            <span v-else key="ispublic" class="flex items-center">
              <span>Allow all SSO authenticated users to see private pages</span>
            </span>
          </span>
        </transition>
      </div>
    </div>

    <transition name="fromBottom" mode="out-in" appear>
      <div v-if="!isSsoEnable && isProjectPrivate">
        <h4 class="mt-8 font-semibold text-neutral-800">
          Allowed email domains
        </h4>
        <p class="text-neutral-800">
          Only users with a following email address domains will have access to
          your project:
        </p>

        <div class="mt-2 domain-list">
          <p
            class="mb-1 text-neutral-700 text-sm p-2 border border-dashed rounded-lg w-fit"
            v-if="projectAllowedDomains.length === 0 && !isDomainAdding"
          >
            No allowed domains yet
          </p>

          <Tag
            v-for="domain in projectAllowedDomains"
            :key="domain.id"
            class="my-1 mr-2"
            @close="destroyDomain(domain)"
            >{{ domain.email_domain }}</Tag
          >

          <transition name="fromLeft" mode="out-in" appear>
            <p
              class="mb-1 text-neutral-700"
              v-if="isDomainRemoving || isDomainAdding"
            >
              Working …
            </p>
          </transition>
        </div>

        <form action class="mt-2">
          <div class="flex items-center">
            <input
              type="text"
              class="input mr-2"
              placeholder="Example: company.com"
              v-model="newDomain"
              :disabled="isDomainAdding"
            />
            <button
              type="submit"
              class="btn-main flex-shrink-0"
              @click.prevent="addDomain()"
              :disabled="isDomainAdding"
            >
              Add allowed domain
            </button>
          </div>
          <p v-if="domainError.length > 1" class="text-red-500 text-sm mt-1">
            {{ domainError }}
          </p>
        </form>

        <h4 class="mt-8 font-semibold text-neutral-800">
          Allowed email addresses
        </h4>
        <p class="text-neutral-800">
          Only users with a following email addresses will have access to your
          project:
        </p>

        <privacyTable :emails="projectAllowedEmails"></privacyTable>

      </div>
    </transition>
  </div>
</template>

<script>
import Tag from "../../components/tag";
import privacyTable from "./privacy_email_table";
import PrivacyAlert from "./plan_alert";
import {mapGetters} from "vuex"

export default {
  props: {
    projectPrivate: Boolean,
    allowedDomains: Array,
    allowedEmails: Array,
    isIntercomConnected: Boolean,
    subDomain: String,
    currentProject: Object,
  },
  data: function () {
    return {
      isProjectPrivate: this.projectPrivate,
      isPrivateToggleLoading: false,
      projectAllowedDomains: this.allowedDomains,
      projectAllowedEmails: this.allowedEmails,
      newDomain: "",
      domainError: "",
      isDomainAdding: false,
      isDomainRemoving: false,
      isSsoEnable: this.currentProject.sso_privacy,
    };
  },

  components: {
    privacyTable,
    Tag,
    PrivacyAlert,
  },
  computed:{
  ...mapGetters(['getPlanFeatures','getCurrentProjectPlan']),
    privacyCheck(){
      if(this.getCurrentProjectPlan.name !== "trial" && this.getCurrentProjectPlan.active_status){
        const plans = this.getPlanFeatures;
        return plans[this.getCurrentProjectPlan.name].privacy;
      }
      else{
        return true;
      }
    },
    getAppropriateDescription(){
      if(this.getCurrentProjectPlan.name!="trial"){
        if(this.getCurrentProjectPlan.active_status) return 'Private project is only available to Business and Enterprise users. Please upgrade your plan to use this feature.';
        else return 'Your subscription failed to update. To make your project private, please update your card.'
      }
      else{
        return 'Your trial period has expired. Please upgrade your plan.';
      }
    },
    showSSOPrivacySetting(){
      return (this.currentProject.sso_only && this.isProjectPrivate);
    }
  },
  methods: {
    togglePrivacy: function () {
      this.isPrivateToggleLoading = true;

      Rails.ajax({
        url: "/toggle_privacy",
        type: "POST",
        data: "",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isProjectPrivate = data.is_private;
          this.isPrivateToggleLoading = false;
          this.$emit("privacyUpdate", this.isProjectPrivate);
          this.$store.dispatch('getAllWarnings')
          this.$store.dispatch('fetchCurrentPlan')
        },
        error: (data, status) => {
          console.log(data);
          this.isPrivateToggleLoading = false;
        },
      });
    },

    toggleSSOPrivacy(){
      Rails.ajax({
        url: "/toggle_sso_privacy",
        type: "POST",
        data: "",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isSsoEnable = data.sso_privacy
          this.$store.dispatch('getAllWarnings')
          this.$store.dispatch('fetchCurrentPlan')
        },
        error: (data, status) => {
          console.log(data);
          this.isPrivateToggleLoading = false;
        },
      });
    },

    isDomainValid: function () {
      // Not perfect, but good enough to prevent email addresses and typos
      return /^[\w-]+\.[\.\w+-]*\w$/.test(this.newDomain);
    },

    addDomain: function () {
      this.domainError = "";

      if (!this.isDomainValid()) {
        this.domainError =
          'Not a valid domain. Please enter a domain like "feedbear.com"';
        return;
      }

      this.isDomainAdding = true;
      var params = new FormData();
      params.append("email_domain", this.newDomain);

      Rails.ajax({
        url: "/invited_customers",
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.newDomain = "";
          this.projectAllowedDomains.push(data);
          this.isDomainAdding = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isDomainAdding = false;
        },
      });
    },

    destroyDomain: function (domain) {
      this.isDomainRemoving = true;

      Rails.ajax({
        url: "/invited_customers/" + domain.id,
        type: "DELETE",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.projectAllowedDomains.splice(
            this.projectAllowedDomains.indexOf(domain),
            1
          );
          this.isDomainRemoving = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isDomainRemoving = false;
        },
      });
    },
  },
};
</script>

<style scoped>
.domain-list {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.icon--inline {
  display: inline;
  position: relative;
  top: 2px;
}

.area--disabled {
  opacity: 0.4;
  pointer-events: none;
}

.toggle-wrapper {
  display: flex;
  align-items: center;
}

.button--toggle {
  position: relative;
  width: 74px;
  height: 36px;
  overflow: hidden;
  margin-right: 0.5rem;
}

.button--toggle,
.button--toggle .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #f5f5f5;
  transition: 0.3s ease all;
  z-index: 1;
  border: 1px solid gainsboro;
}

.button--toggle .knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  height: 28px;
  background-color: #d2d2d2;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

.button--toggle .checkbox:active + .knobs:before {
  width: 46px;
  border-radius: 100px;
}

.button--toggle .checkbox:checked:active + .knobs:before {
  margin-left: -26px;
}

.button--toggle .checkbox:checked + .knobs:before {
  content: "";
  left: 42px;
  background-color: #3bd087;
}

.button--toggle .checkbox:checked ~ .layer {
  background-color: #d1f0e1;
  border-color: #a3e6c6;
}

/* Animations */

.fromLeft-move {
  transition: all 250ms;
}
.fromLeft-enter-active,
.fromLeft-leave-active {
  transition: all 250ms;
}
.fromLeft-enter,
.fromLeft-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
</style>
